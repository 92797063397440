import React, {
  Fragment, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getResourcesByParentId } from '../../../actions/Resources';
import { getMaterial, resetMaterial } from '../../../actions/Materials';
import DotMenu from './DotMenu';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import Form from '../../Material/Form';
import DeleteMaterial from '../../ManageContent/DeleteContent/DeleteMaterial';
import CdnFiles from '../../ManageContent/CdnFiles';
import Tabs from './Tabs';
import MaterialIcon from '../../ManageContent/MaterialIcon';
import AudioFiles from '../../ManageContent/AudioFiles';
import './style.css';

const MaterialSideSheet = ({ className = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const material = useSelector((state) => state.material);
  const sideSheet = useSelector((state) => state.sideSheet);
  // Local State
  const [editMode, setEditMode] = useState(sideSheet?.data?.editMode || false);
  const [activeTab, setActiveTab] = useState('settings');

  const hasFiles = useMemo(() => {
    return (Array.isArray(material?.cdnFiles) && material?.cdnFiles.length > 0);
  }, [material?.cdnFiles]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetMaterial());
    };
  }, [dispatch]);

  return (
    <div className={`card ${className}`}>
      <div className='card-header d-flex align-items-center justify-content-between'>
        <div
          className="h6 m-0 font-weight-bold"
          style={{
            width: '72%'
          }}
        >
          <span
            className="d-flex align-items-center"
          >
            <MaterialIcon
              className='d-none d-sm-block mr-2'
              promptType={material?.promptType}
              fileUploadFlow={material?.fileUploadFlow}
            />
            <span
              className="text-nowrap"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {material?.title}
            </span>
          </span>
        </div>
        <div className='d-flex align-items-center'>
          {material?.isDraft && (
            <span className='h6 mb-0 mx-2'>
              <span className='badge alert-warning alert py-1 px-2 m-0'>
                draft
              </span>
            </span>
          )}
          <DotMenu
            materialId={material?.id}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        </div>
      </div>

      {editMode && (
        <Tabs
          cardStyle
          hasFiles={hasFiles}
          isDraft={material?.isDraft}
          className='pt-3'
          activeTab={activeTab}
          setActiveTab={(tab) => {
            setActiveTab(tab);
          }}
        />
      )}

      <div className='card-body overflow-y-scroll'>
        {editMode ? (
          <>
            <div
              className={activeTab === 'settings' ? 'd-block' : 'd-none'}
            >
              <Form
                contentType="materials"
                onSuccess={() => {
                  dispatch(getMaterial(material?.id));
                  setEditMode(false);
                }}
                onCancel={() => {
                  setEditMode(false);
                }}
              />

              {material?.id && (
                <DeleteMaterial
                  contentType="materials" // supabase
                  contentId={material?.id}
                  title={material?.title}
                />
              )}
            </div>

            {material?.id && (
              <div
                className={activeTab === 'files' ? 'd-block' : 'd-none'}
              >
                <CdnFiles
                  id="material-integration-files"
                  contentId={material?.id}
                  contentType="materials" // supabase
                  files={material?.cdnFiles}
                  // editMenu
                  // resourcePreview
                  header={false}
                  hiddenColumns={['createdAt', 'body']}
                  removeFileCallback={() => {
                    dispatch(getResourcesByParentId(material?.id, 'materials'));
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <RenderMarkdown
              enableNewEditor
              source={material?.body}
            />

            <AudioFiles
              files={material?.cdnFiles}
            />

            {material?.id && (
              <CdnFiles
                id="material-integration-files"
                contentId={material?.id}
                contentType="materials" // supabase
                files={material?.cdnFiles}
                // editMenu
                // resourcePreview
                header={false}
                hiddenColumns={['createdAt', 'body']}
                removeFileCallback={() => {
                  dispatch(getResourcesByParentId(material?.id, 'materials'));
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MaterialSideSheet;
