import React from 'react';
import { FaPencil } from 'react-icons/fa6';
import { FaMicrophone, FaPhotoVideo } from 'react-icons/fa';

const NoteTypeNav = ({ selectedTab = 'TEXT', setSelectedTab }) => {
  return (
    <div
      className="p-1 d-flex rounded justify-content-between w-100"
      style={{
        boxShadow: '2px 1px 7px rgba(0,0,0, .5) inset',
        backgroundColor: '#2c3d50'
      }}
    >
      <button
        type='button'
        className={`${selectedTab === 'TEXT' ? 'bg-white' : ''} flex-grow-1 py-2 rounded rounded-3`}
        onClick={() => {
          setSelectedTab('TEXT');
        }}
      >
        <FaPencil
          color={selectedTab === 'TEXT' ? 'black' : 'white'}
          size={25}
          className="text-center w-100 py-1"
        />
      </button>
      <button
        type='button'
        className={`${selectedTab === 'MEDIA' ? 'bg-white' : ''} flex-grow-1 py-2 rounded rounded-3`}
        onClick={() => {
          setSelectedTab('MEDIA');
        }}
      >
        <FaPhotoVideo
          color={selectedTab === 'MEDIA' ? 'black' : 'white'}
          size={25}
          className="text-center w-100 py-1"
        />
      </button>
      <button
        type='button'
        className={`${selectedTab === 'AUDIO' ? 'bg-white' : ''} flex-grow-1 py-2 rounded rounded-3`}
        onClick={() => setSelectedTab('AUDIO')}
      >
        <FaMicrophone
          color={selectedTab === 'AUDIO' ? 'black' : 'white'}
          size={25}
          className="text-center w-100 py-1"
        />
      </button>
    </div>
  );
};

export default NoteTypeNav;
