import React from 'react';
import { FaCamera, FaImages } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as aws from '../../services/aws';
import { coerceToResourceName, createResource } from '../../services/resources';
import { getResourcesByParentId } from '../../actions/Resources';
import { AWS_USER_PREFIX } from '../../constants/aws';
import CdnFiles from '../ManageContent/CdnFiles';
import { createMaterial, updateMaterial } from '../../services/materials';
import { getMaterial, getMaterials } from '../../actions/Materials';
import heapAnalytics from '../../utils/heapAnalytics';

const MediaNotes = ({
  className,
  // contentId,
  contentType,
  workflow,
  // onSuccessCallback,
  // onCancelCallback,
  onErrorCallback,
  noteType,
  title,
  bodyRef
}) => {
  const dispatch = useDispatch();
  // Redux
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const material = useSelector((state) => state.material);
  // Organization
  const orgId = organization?.id || null;
  // Current User
  const userId = currentUser?.id;
  const locationId = currentUser?.locationId;

  const createTrackingEvent = (eventName, data) => {
    heapAnalytics.track(eventName, {
      type: 'manual',
      ...data,
      orgId,
      userId,
      locationId,
      materialId: material?.id
    });
  };

  const somethingWentWrong = (error) => {
    console.error(error);
    toast.error('Something went wrong, refresh and try again', {
      autoClose: false
    });

    if (onErrorCallback && typeof onErrorCallback === 'function') {
      onErrorCallback();
    }
  };

  const createEntry = (dataFromFields) => {
    const newMaterial = {
      ...dataFromFields,
      orgId,
      userId,
      isDraft: true,
      workflowType: workflow || null,
      locationId
    };

    // toast.info('Processing...', { toastId: 'createMaterialInfo' });

    createTrackingEvent('Material - Create - Preflight', {
      ...newMaterial
    });

    return new Promise((resolve, reject) => {
      createMaterial({
        ...newMaterial
      }).then((response) => {
        // toast.dismiss('createMaterialInfo');
        // toast.success('Material saved!');

        createTrackingEvent('Material - Create - Success', {
          materialId: response?.id,
          workflowType: workflow,
          noteType
        });

        dispatch(getMaterial(response?.id));
        dispatch(getMaterials({ userId, orgId }));

        resolve({ materialId: response?.id });
      }).catch((error) => {
        createTrackingEvent('Error - Material - Create', {
          error: 'Problem creating Material',
          workflowType: workflow,
          noteType
        });
        somethingWentWrong(error);
        reject(error);
      });
    });
  };

  const updateEntry = (dataFromFields) => {
    const dataToSave = {
      ...dataFromFields
    };

    return new Promise((resolve) => {
      updateMaterial(dataToSave, material?.id).then(() => {
        toast.success('Material updated!');
        dispatch(getMaterials({ userId, orgId }));

        resolve({ materialId: material?.id });
      });
    });
  };

  const handleSave = () => {
    const dataFromFields = {
      title
    };

    if (!title || title.trim() === '') {
      toast.error('A title is required');
      return;
    }

    if (bodyRef && bodyRef.current && bodyRef.current.isDirty) {
      dataFromFields.body = bodyRef.current.getContent();
    }

    return new Promise((resolve) => {
      if (material?.id) {
        updateEntry(dataFromFields).then(({ materialId }) => {
          resolve({ materialId });
        });
      } else {
        createEntry(dataFromFields).then(({ materialId }) => {
          resolve({ materialId });
        });
      }
    });
  };

  const handleInsertUploadedFile = (fileObj) => {
    // const { url, name } = fileObj;
    // ADD Resource

    handleSave().then(({ materialId }) => {
      const dataToCreate = {
        ...fileObj,
        orgId,
        userId: currentUser?.id,
        userName: currentUser?.name,
        type: fileObj.type,
        parentType: contentType,
        parentId: materialId
      };

      createResource(dataToCreate).then(() => {
        dispatch(getResourcesByParentId(materialId, contentType)).then(() => {
          toast.success('File uploaded!');
        });
      }).catch(() => {
        toast.error('Something went wrong');
      });
    });
  };

  // const handleOnChange = (blob) => {
  //   // optimistically add images using blob information
  // };

  const onFileUploadHook = (e) => {
    const { files } = e.currentTarget;
    const blob = files[0];

    // handleOnChange(blob);

    toast.info('Uploading file...');

    aws.uploadFile({
      prefix: `${AWS_USER_PREFIX}/${orgId}`,
      file: blob,
      orgId
    }).then(({ url, id }) => {
      const fileObj = { url, id };

      if (blob.name) {
        fileObj.name = coerceToResourceName({
          str: blob.name,
          fixCamelCase: true,
          fixHyphens: true,
          fixUnderScores: true
        });
      }

      if (blob.type) fileObj.type = blob.type;
      if (blob.size) fileObj.size = blob.size;

      // Add file as embed syntax in editor
      handleInsertUploadedFile(fileObj);
    });
  };

  const onClickFiles = (id) => {
    document.getElementById(id).click();
  };

  return (
    <div
      className={className}
    >
      <div
        className='d-flex align-items-center px-3 py-4 rounded mb-3'
        style={{
          justifyContent: 'space-evenly',
          color: '#c0c7cb',
          backgroundColor: '#222f3e',
          borderColor: '#161f29'
        }}
      >
        <div className='mx-3'>
          <button
            className='btn btn-md btn-outline-primary py-3 px-5'
            onClick={() => {
              onClickFiles('mediaFilesCamera');
            }}
            type='button'
          >
            <FaCamera size={30} />
          </button>
          <div className='h6 mb-0 mt-2 text-white text-center'>
            Camera
          </div>
          <input
            id="mediaFilesCamera"
            type="file"
            accept="image/*;capture=camera"
            className='d-none'
            onChange={onFileUploadHook}
          />
        </div>
        <div className='mx-3'>
          <button
            className='btn btn-md btn-outline-primary py-3 px-5'
            onClick={() => {
              onClickFiles('mediaFilesBrowse');
            }}
            type='button'
            onChange={onFileUploadHook}
          >
            <FaImages size={30} />
          </button>
          <div className='h6 mb-0 mt-2 text-white text-center'>
            Browse Files
          </div>
          <input
            id="mediaFilesBrowse"
            type="file"
            accept="image/*"
            className='d-none'
            onChange={onFileUploadHook}
          />
        </div>
      </div>

      <CdnFiles
        id="material-integration-files"
        className='mt-3'
        contentId={material?.id}
        contentType="materials" // supabase
        files={material?.cdnFiles}
        // editMenu
        // resourcePreview
        header={false}
        hiddenColumns={['createdAt', 'body']}
        removeFileCallback={() => {
          dispatch(getResourcesByParentId(material?.id, 'materials'));
        }}
      />
    </div>
  );
};

export default MediaNotes;
